import clsx from 'clsx';

import { FC, MouseEventHandler, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import {
  templateDescriptionByType,
  templateImageByType,
  templateTitleByType,
  templatesPriority,
} from 'modules/loans/constants/config';
import {
  selectLoanCalculatorSavedValues,
  selectTemplatesReducer,
} from 'modules/loans/store/selectors';
import { requestLoanTemplates } from 'modules/loans/store/thunks';
import { LoanTemplate, LoanTemplateType } from 'modules/loans/types';
import LoanCalculator from 'modules/loans/views/LoanCalculator';

import routesByName from 'constants/routesByName';

import { ErrorCard } from 'components/common';
import { Image, Loader, Mark, VerticalDelimiter } from 'components/ui';

import useStoreEntity from 'hooks/useStoreEntity';
import useTablet from 'hooks/useTablet';

import { useTranslation } from 'libs/i18n';

import classes from './New.module.scss';

interface NewUIProps {
  templates: LoanTemplate[];
  initialTemplate: LoanTemplate;
}
const NewUI: FC<NewUIProps> = ({ templates, initialTemplate }) => {
  const navigate = useNavigate();
  const translate = useTranslation();
  const isTablet = useTablet();

  const handleTemplateClick = useCallback<MouseEventHandler<HTMLDivElement>>(
    (e) => {
      navigate(routesByName.loans('new', e.currentTarget.dataset.id as LoanTemplateType));
    },
    [navigate],
  );

  return (
    <div className={classes.root}>
      <div className={classes.templatesContainer}>
        <span className={classes.templateLabel}>{translate('LOANS_PICK_TEMPLATE')}</span>
        <div className={classes.templates}>
          {templates.map((t) => (
            <div
              key={t.id}
              data-id={t.type}
              onClick={handleTemplateClick}
              className={clsx(
                classes.templateCard,
                initialTemplate.type === t.type && classes.active,
              )}
            >
              <Image name={templateImageByType[t.type]} path="loans" />
              <div className="column gap-0-5">
                <div className="row aic gap-1">
                  <span className={classes.title}>{translate(templateTitleByType[t.type])}</span>
                  {t.type === 'ONLY_INTEREST' && (
                    <Mark className={classes.promoMark} variant="green">
                      {translate('PROMO')} {t.ltvRange[+Object.keys(t.ltvRange)[0]]?.percent}%
                    </Mark>
                  )}
                </div>
                <p>{translate(templateDescriptionByType[t.type])}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isTablet ? null : (
        <>
          <VerticalDelimiter />
          <LoanCalculator templateType={initialTemplate.type} variant="short" />
        </>
      )}
    </div>
  );
};

export const New = () => {
  const translate = useTranslation();

  const calculatorSavedValues = useSelector(selectLoanCalculatorSavedValues);

  const { entityReducer: templatesReducer, fetchEntity: fetchTemplates } = useStoreEntity(
    selectTemplatesReducer,
    requestLoanTemplates,
  );

  const sortedTemplates = useMemo(
    () =>
      [...templatesReducer.data].sort(
        (a, b) => templatesPriority.indexOf(a.type) - templatesPriority.indexOf(b.type),
      ),
    [templatesReducer.data],
  );

  const initialTemplate = useMemo(
    () =>
      sortedTemplates.find((t) => t.id === calculatorSavedValues.templateId) || sortedTemplates[0],
    [sortedTemplates, calculatorSavedValues.templateId],
  );

  if (templatesReducer.meta.loading && !templatesReducer.meta.loaded) {
    return <Loader centered />;
  }

  if (templatesReducer.meta.error || !initialTemplate) {
    return <ErrorCard retry={fetchTemplates} label={translate('LOANS_TEMPLATE_IS_MISSING')} />;
  }

  return <NewUI templates={sortedTemplates} initialTemplate={initialTemplate} />;
};
