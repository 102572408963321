import clsx from 'clsx';
import get from 'lodash/get';

import { FC, useCallback, useMemo } from 'react';
import { Field, Form, FormRenderProps } from 'react-final-form';
import { useSelector } from 'react-redux';

import { selectWallets } from 'modules/accounts/store/selectors';
import { requestCreateWallet } from 'modules/accounts/store/thunks';
import { TradingWallet, Wallet } from 'modules/accounts/types';
import useSideBar from 'modules/app/hooks/useSideBar';
import sidebarTemplates from 'modules/app/views/Sidebar/sidebarTemplates';
import useRateToDefaultCurrency from 'modules/exchange/hooks/useRateToDefaultCurrency';
import { selectAllowedDirectionsForExchange } from 'modules/exchange/store/selectors';
import { requestExchange } from 'modules/exchange/store/thunks';
import { ExchangeResponse } from 'modules/exchange/types';
import CurrencyRateCard, {
  CurrencyRateCardProps,
} from 'modules/exchange/views/components/CurrencyRateCard';
import { useDispatch } from 'store';

import { AgreementText } from 'components/common';
import { CurrencyAmountField, SubmitButton } from 'components/form';
import { Button, DottedLine, Loader } from 'components/ui';
import { ButtonProps } from 'components/ui/Button';
import { CurrencyAmount, CurrencyAmountInputProps } from 'components/ui/CurrencyAmountInput';

import { getTranslation, useTranslation } from 'libs/i18n';
import { errorToast } from 'libs/toast';
import yup, { makeValidate } from 'libs/yup';

import {
  MINIMUM_AMOUNT_CRYPTO,
  MINIMUM_AMOUNT_FIAT,
  fiatCurrencies,
  findWallet,
  formatCurrencyWithSymbol,
  getCurrencyLabelByCode,
  isFiat,
  mainCryptoCodes,
  stableCoinsCodes,
} from 'utils/currency';

import { CurrenciesDirection, CurrencyCode } from 'types';

import classes from './ExchangeForm.module.scss';
import useDecorator from './useDecorator';

// Coins that can be exchanged with any coin
const universalCurrencies = [...mainCryptoCodes, ...stableCoinsCodes, ...fiatCurrencies];

const isUniversalCurrency = (currencyCode: CurrencyCode) =>
  universalCurrencies.includes(currencyCode);

export interface ExchangeFormValues {
  from: CurrencyAmount;
  to: CurrencyAmount;
  commissionAmount: number;
  commissionPercent: number | null;
  commissionMinAmount: number | null;
  exchangeRate: number;
  exchangeRateReversed: number;
}

interface ExchangeInternalFormProps extends FormRenderProps<ExchangeFormValues> {
  wallets: Wallet[];
  isLight?: boolean;
  submitButtonProps?: ButtonProps;
  toFieldProps?: Partial<CurrencyAmountInputProps>;
  formContainerClassName?: string;
  currencyChartComponent?: FC<CurrencyRateCardProps>;
  allowedDirections: CurrenciesDirection[];
}

const CommissionLabel: FC<{
  amount: number;
  currencyCode: CurrencyCode;
  percent: number | null;
  minFixedAmount: number | null;
}> = ({ amount, currencyCode, percent, minFixedAmount }) => {
  const translate = useTranslation();

  const commissionInfoLabel = useMemo(() => {
    let result: string | null = null;
    if (percent) {
      result = `${percent}%`;
    }
    if (minFixedAmount) {
      if (result) {
        result += ', ';
      }
      result +=
        translate('PAYMENT_FEE_MIN').toLowerCase() +
        ' ' +
        formatCurrencyWithSymbol(minFixedAmount, currencyCode);
    }
    return result;
  }, [currencyCode, translate, percent, minFixedAmount]);

  return (
    <div className="row gap-0-5">
      <span className={classes.underFieldLabel}>
        {translate('FEE')}: {formatCurrencyWithSymbol(amount, currencyCode)}
      </span>
      {percent === null ? (
        <Loader className={classes.loader} />
      ) : (
        <span className={classes.underFieldLabel}>({commissionInfoLabel})</span>
      )}
    </div>
  );
};
const alwaysToCurrency = [...fiatCurrencies, ...stableCoinsCodes];

const ExchangeFormInternalForm: FC<ExchangeInternalFormProps> = ({
  wallets,
  values,
  form,
  handleSubmit,
  isLight,
  toFieldProps,
  submitButtonProps,
  formContainerClassName,
  allowedDirections,
  errors,
}) => {
  const sidebar = useSideBar();

  const { from, to } = values;

  const translate = useTranslation();

  const { calculating } = useDecorator(values, form);

  const isDirectionAllowed = useCallback(
    (from: CurrencyCode, to: CurrencyCode) => allowedDirections.includes(`${from}/${to}`),
    [allowedDirections],
  );

  const { rate: rateToDefaultCurrency, defaultCurrency } = useRateToDefaultCurrency(from.currency, {
    autoFetch: true,
  });

  const getCurrenciesList = useCallback(
    (currencyForExchange: CurrencyCode, forFromField?: boolean) => {
      let result: Wallet[] = [];

      if (forFromField) {
        result = wallets.filter(
          (w) =>
            w.currencyCode === currencyForExchange ||
            isDirectionAllowed(w.currencyCode, currencyForExchange),
        );
      } else {
        result = wallets.filter(
          (w) =>
            w.currencyCode === currencyForExchange ||
            isDirectionAllowed(currencyForExchange, w.currencyCode),
        );
      }

      if (isUniversalCurrency(currencyForExchange)) {
        return result;
      }
      return result.filter(
        (w) => isUniversalCurrency(w.currencyCode) || w.currencyCode === currencyForExchange,
      );
    },
    [isDirectionAllowed, wallets],
  );

  const fromCurrenciesList = useMemo(
    () => getCurrenciesList(to.currency, true),
    [getCurrenciesList, to.currency],
  );
  const toCurrenciesList = useMemo(
    () => getCurrenciesList(from.currency),
    [getCurrenciesList, from.currency],
  );

  const isInsufficientFunds =
    get(errors, 'from.amount') === translate('VALIDATION_INSUFFICIENT_FUNDS');

  const handleDepositFunds = useCallback(() => {
    sidebar.open(
      ...sidebarTemplates.paymentProcess({
        isDeposit: true,
        currencyCode: from.currency,
      }),
    );
  }, [sidebar, from.currency]);

  const useReversedRate = useMemo(
    () => alwaysToCurrency.includes(values.from.currency),
    [values.from.currency],
  );
  return (
    <form onSubmit={handleSubmit} className="column gap-3">
      <DottedLine />
      <CurrencyRateCard
        variant="bare"
        from={from.currency}
        to={to.currency}
        chartClassName={classes.chart}
        isLight={isLight}
        rate={useReversedRate ? values.exchangeRateReversed : values.exchangeRate}
        rateLoading={calculating}
      />
      <DottedLine />
      <div className={clsx(formContainerClassName, 'column gap-2')}>
        <Field
          name="from"
          component={CurrencyAmountField}
          placeholder={translate('PLACEHOLDER_ENTER_AMOUNT')}
          showMaxAmountButton
          showBalance
          label={translate('PLACEHOLDER_ENTER_AMOUNT')}
          currenciesList={fromCurrenciesList}
          showErrorOnlyIfHasAmountValue
          size="md-plus"
          underComponent={
            <span className={classes.underFieldLabel}>
              {getCurrencyLabelByCode(defaultCurrency)}:{' '}
              {formatCurrencyWithSymbol(+from.amount * rateToDefaultCurrency, defaultCurrency)}
            </span>
          }
        />
        <Field
          name="to"
          // @ts-ignore
          component={CurrencyAmountField}
          placeholder={translate('PAYMENT_YOU_WILL_GET')}
          label={translate('PAYMENT_YOU_WILL_GET')}
          currenciesList={toCurrenciesList}
          showErrorOnlyIfHasAmountValue
          size="md-plus"
          underComponent={
            <CommissionLabel
              amount={values.commissionAmount}
              percent={values.commissionPercent}
              minFixedAmount={values.commissionMinAmount}
              currencyCode={from.currency}
            />
          }
          {...toFieldProps}
        />
      </div>
      <div className="column gap-1-5">
        {isInsufficientFunds ? (
          <Button loading={calculating} fullWidth showShadow onClick={handleDepositFunds}>
            {translate('DEPOSIT_CURRENCY', {
              currencyLabel: getCurrencyLabelByCode(from.currency),
            })}
          </Button>
        ) : (
          <SubmitButton loading={calculating} fullWidth showShadow {...submitButtonProps}>
            {translate('EXCHANGE')}
          </SubmitButton>
        )}
        <AgreementText variant="proceeding" light={isLight} />
      </div>
    </form>
  );
};

export interface ExchangeSuccessCallbackPayload {
  response: ExchangeResponse;
  values: ExchangeFormValues;
}
export interface ExchangeFormProps extends Partial<ExchangeFormValues> {
  onSuccess: (data: ExchangeSuccessCallbackPayload) => void;
  isLight?: boolean;
  submitButtonProps?: ButtonProps;
  toFieldProps?: Partial<CurrencyAmountInputProps>;
  formContainerClassName?: string;
  currencyChartComponent?: FC<CurrencyRateCardProps>;
}

const ExchangeForm: FC<ExchangeFormProps> = ({
  from = { currency: 'BTC', amount: '' },
  to = { currency: 'EUR', amount: '' },
  onSuccess,
  isLight,
  submitButtonProps,
  toFieldProps,
  formContainerClassName,
  currencyChartComponent,
}) => {
  const dispatch = useDispatch();
  const translate = useTranslation();

  const wallets = useSelector(selectWallets);
  const allowedDirections = useSelector(selectAllowedDirectionsForExchange);

  const handleSubmit = useCallback(
    async (values: ExchangeFormValues) => {
      const fromWallet = findWallet(wallets, values.from.currency) as TradingWallet;
      let toWallet = findWallet(wallets, values.to.currency) as TradingWallet;

      if (!fromWallet) {
        errorToast(translate('ACCOUNTS_WALLET_NOT_FOUND', { currencyCode: values.from.currency }));
        return;
      }
      if (!toWallet) {
        errorToast(translate('ACCOUNTS_WALLET_NOT_FOUND', { currencyCode: values.to.currency }));
        return;
      }
      if (!toWallet.exist) {
        const { success, data } = await dispatch(
          requestCreateWallet({
            issuerId: toWallet.issuerId,
          }),
        );
        if (success && data) {
          toWallet = data;
        } else {
          return;
        }
      }

      const { success, data } = await dispatch(
        requestExchange({
          fromCurrency: values.from.currency,
          fromIssuerId: fromWallet.issuerId,
          amount: +values.from.amount,
          toCurrency: values.to.currency,
          toIssuerId: toWallet.issuerId,
        }),
      );
      if (success) {
        onSuccess({ values, response: data });
      }
    },
    [onSuccess, translate, wallets, dispatch],
  );

  const initialValues: ExchangeFormValues = useMemo(
    () => ({
      from,
      to,
      commissionAmount: 0,
      commissionPercent: null,
      commissionMinAmount: null,
      exchangeRate: 0,
      exchangeRateReversed: 0,
    }),
    [from, to],
  );

  const validate = useCallback(
    (values: ExchangeFormValues) => {
      const wallet = wallets.find((w) => w.currencyCode === values.from.currency);

      const minAmountFrom = isFiat(values.from.currency)
        ? MINIMUM_AMOUNT_FIAT
        : MINIMUM_AMOUNT_CRYPTO;
      const minAmountTo = isFiat(values.to.currency) ? MINIMUM_AMOUNT_FIAT : MINIMUM_AMOUNT_CRYPTO;

      const schema = yup.object().shape({
        from: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              minAmountFrom,
              getTranslation('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(minAmountFrom, values.from.currency),
              }),
            )
            .max(wallet?.amount || 0, getTranslation('VALIDATION_INSUFFICIENT_FUNDS'))
            .requiredDefault(),
        }),
        to: yup.object().shape({
          amount: yup
            .number()
            .transform((value) => (isNaN(value) ? 0 : value))
            .min(
              minAmountTo,
              getTranslation('VALIDATION_MIN_AMOUNT', {
                minLabel: formatCurrencyWithSymbol(minAmountTo, values.to.currency),
              }),
            )
            .requiredDefault(),
        }),
      });

      return makeValidate(schema)(values);
    },
    [wallets],
  );
  return (
    <Form
      validate={validate}
      onSubmit={handleSubmit}
      initialValues={initialValues}
      // @ts-ignore
      render={ExchangeFormInternalForm}
      wallets={wallets}
      toFieldProps={toFieldProps}
      isLight={isLight}
      submitButtonProps={submitButtonProps}
      formContainerClassName={formContainerClassName}
      currencyChartComponent={currencyChartComponent}
      allowedDirections={allowedDirections}
    />
  );
};
export default ExchangeForm;
