import { FC } from 'react';

import AccountStatement from 'modules/accounts/views/AccountStatement';
import AccountsTransactionsHistory from 'modules/accounts/views/AccountsTransactionsHistoryDrawerView';
import TransactionDetails from 'modules/accounts/views/TransactionDetails';
import AccountOperationsMore from 'modules/accounts/views/sidebarViews/AccountOperationsMore';
import { SideBarStackItemKey } from 'modules/app/types';
import AppOperations from 'modules/app/views/AppOperations';
import NavMenu from 'modules/app/views/AuthedApp/components/NavMenu';
import ConfirmOTPCode from 'modules/app/views/ConfirmOTPCode';
import Confirmation from 'modules/app/views/Confirmation';
import MenuListSidebarView from 'modules/app/views/MenuListSidebarView';
import SelectCurrency from 'modules/app/views/SelectCurrency';
import SessionExpiredWarning from 'modules/app/views/SessionExpiredWarning';
import CheckTFACode from 'modules/auth/views/CheckTFACode';
import EmergencyResetCode from 'modules/auth/views/EmergencyResetCode';
import SetupTFA from 'modules/auth/views/SetupTFA';
import BankCardMoreOptions from 'modules/cryptoBankCard/views/BankCardMoreOptions';
import BankCards from 'modules/cryptoBankCard/views/BankCards';
import CryptoBankCardActivate from 'modules/cryptoBankCard/views/CryptoBankCardActivate';
import CryptoBankCardActivateSuccess from 'modules/cryptoBankCard/views/CryptoBankCardActivateSuccess';
import CryptoBankCardChangePinCode from 'modules/cryptoBankCard/views/CryptoBankCardChangePinCode';
import CryptoBankCardConnectAccount from 'modules/cryptoBankCard/views/CryptoBankCardConnectAccount';
import CryptoBankCardIntroducing from 'modules/cryptoBankCard/views/CryptoBankCardIntroducing';
import CryptoBankCardTransactions from 'modules/cryptoBankCard/views/CryptoBankCardTransactions';
import CryptoCardFeeAndConditions from 'modules/cryptoBankCard/views/CryptoCardFeeAndConditions';
import DescriptionCards from 'modules/cryptoBankCard/views/DescriptionCards';
import GoogleWalletOptionAd from 'modules/cryptoBankCard/views/GoogleWalletOptionAd';
import OrderBankCardCalculatingShippingCosts from 'modules/cryptoBankCard/views/OrderBankCardCalculatingShippingCosts';
import OrderBankCardChooseType from 'modules/cryptoBankCard/views/OrderBankCardChooseType';
import OrderBankCardConfirmation from 'modules/cryptoBankCard/views/OrderBankCardConfirmation';
import OrderBankCardDeliveryMethod from 'modules/cryptoBankCard/views/OrderBankCardDeliveryMethod';
import OrderBankCardShippingDetails from 'modules/cryptoBankCard/views/OrderBankCardShippingDetails';
import TerminateBankCard from 'modules/cryptoBankCard/views/TerminateBankCard';
import RentingFAQ from 'modules/cryptoRenting/views/RentingFAQ';
import RentingNewCoinsAd from 'modules/cryptoRenting/views/RentingNewCoinsAd';
import RentingSetup from 'modules/cryptoRenting/views/RentingSetup';
import RentingSetupConfirm from 'modules/cryptoRenting/views/RentingSetupConfirm';
import RentingSetupSuccess from 'modules/cryptoRenting/views/RentingSetupSuccess';
import DigitalAccountCancelSubscription from 'modules/digitalAccount/views/CancelSubscription';
import DigitalAccountCancelSubscriptionSuccess from 'modules/digitalAccount/views/CancelSubscriptionSuccess';
import DigitalAccountChooseSubscription from 'modules/digitalAccount/views/ChooseSubscription';
import DowngradeSubscriptionToStandard from 'modules/digitalAccount/views/DowngradeSubscriptionToStandard';
import DigitalAccountGetSubscription from 'modules/digitalAccount/views/GetSubscription';
import DigitalAccountGetSubscriptionConfirm from 'modules/digitalAccount/views/GetSubscriptionConfirm';
import DigitalAccountGetSubscriptionSuccess from 'modules/digitalAccount/views/GetSubscriptionSuccess';
import IbanAccountCreatingDeposit from 'modules/digitalAccount/views/IbanAccountCreatingDeposit';
import IbanAccountCreatingPrompt from 'modules/digitalAccount/views/IbanAccountCreatingPrompt';
import IbanAccountCreatingStatus from 'modules/digitalAccount/views/IbanAccountCreatingStatus';
import DigitalAccountSubscriptionManagement from 'modules/digitalAccount/views/SubscriptionManagement';
import DigitalAccountSubscriptionRequested from 'modules/digitalAccount/views/SubscriptionRequested';
import DigitalAccountSubscriptionVerificationPending from 'modules/digitalAccount/views/SubscriptionVerificationPending';
import SwitchDefaultIban from 'modules/digitalAccount/views/SwitchDefaultIban';
import Exchange from 'modules/exchange/views/Exchange';
import ExchangeSuccess from 'modules/exchange/views/ExchangeSuccess';
import FollowedCurrencies from 'modules/exchange/views/FollowedCurrencies';
import CreateInvoice from 'modules/invoicing/views/CreateInvoice';
import CreateInvoiceConfirmation from 'modules/invoicing/views/CreateInvoiceConfirmation';
import EditInvoiceStep from 'modules/invoicing/views/EditInvoiceStep';
import InvoiceCreated from 'modules/invoicing/views/InvoiceCreated';
import InvoiceFull from 'modules/invoicing/views/InvoiceFull';
import Invoices from 'modules/invoicing/views/Invoices';
import SavedClients from 'modules/invoicing/views/SavedClients';
import LoanAddCollateral from 'modules/loans/views/AddCollateral';
import LoanAddCollateralConfirm from 'modules/loans/views/AddCollateralConfirm';
import LoanAddCollateralSuccess from 'modules/loans/views/AddCollateralSuccess';
import LoanLiquidationRiskReduced from 'modules/loans/views/LiquidationRiskReduced';
import LoanAutoPaymentFailed from 'modules/loans/views/LoanAutoPaymentFailed';
import LoanGetSuccess from 'modules/loans/views/LoanGetSuccess';
import LoanMarginCall from 'modules/loans/views/LoanMarginCall';
import LoanRepaid from 'modules/loans/views/LoanRepaid';
import LoanRepayment from 'modules/loans/views/LoanRepayment';
import TemplateDescription from 'modules/loans/views/TemplateDescription';
import AccountDetails from 'modules/payment/views/AccountDetails';
import PaymentAddSavedCryptoWallet from 'modules/payment/views/AddEditSavedCryptoWallet';
import PaymentAddSavedBankCard from 'modules/payment/views/AddSavedBankCard';
import PaymentDepositApplePay from 'modules/payment/views/DepositApplePay';
import PaymentDepositByDetails from 'modules/payment/views/DepositByDetails';
import PaymentDepositCrypto from 'modules/payment/views/DepositCrypto';
import PaymentDepositSendBankCard from 'modules/payment/views/DepositSendBankCard';
import FinishedOperation from 'modules/payment/views/FinishedOperation';
import PaymentPickCurrency from 'modules/payment/views/PickCurrency';
import PaymentPickOperation from 'modules/payment/views/PickOperation';
import SelectCryptoNetwork from 'modules/payment/views/SelectCryptoNetwork';
import SelectCryptoNetworkConfirmation from 'modules/payment/views/SelectCryptoNetworkConfirmation';
import PaymentSendExternalWallet from 'modules/payment/views/SendExternalWallet';
import PaymentSendNebeusUser from 'modules/payment/views/SendNebeusUser';
import PaymentSendSEPA from 'modules/payment/views/SendSEPA';
import PaymentSendToDAOrCA from 'modules/payment/views/SendToDAOrCA';
import PaymentSendWire from 'modules/payment/views/SendWire';
import AddEditCounterparty from 'modules/phonebook/views/AddEditCounterparty';
import AddEditPaymentDetails from 'modules/phonebook/views/AddEditPaymentDetails';
import Counterparties from 'modules/phonebook/views/Counterparties';
import Counterparty from 'modules/phonebook/views/Counterparty';
import SmartTraderActivated from 'modules/smartTrader/views/SmartTraderActivated';
import SmartTraderConfigureTrade from 'modules/smartTrader/views/SmartTraderConfigureTrade';
import SmartTraderExchange from 'modules/smartTrader/views/SmartTraderExchange';
import SmartTraderExchangeSuccess from 'modules/smartTrader/views/SmartTraderExchangeSuccess';
import SmartTraderInsufficientFunds from 'modules/smartTrader/views/SmartTraderInsufficientFunds';
import SmartTraderPickAssets from 'modules/smartTrader/views/SmartTraderPickAssets';
import SmartTraderSelectTradingPair from 'modules/smartTrader/views/SmartTraderSelectTradingPair';
import SmartTraderSummaryConfirmation from 'modules/smartTrader/views/SmartTraderSummaryConfirmation';
import SmartTraderWelcome from 'modules/smartTrader/views/SmartTraderWelcome';
import StakingOperationConfirm from 'modules/staking/views/StakingOperationConfirm';
import StakingSetup from 'modules/staking/views/StakingSetup';
import StakingSuccessOperation from 'modules/staking/views/StakingSuccessOperation';
import Unstake from 'modules/staking/views/Unstake';
import CustomerQuestionnaire from 'modules/user/views/CustomerQuestionnaire';
import Notifications from 'modules/user/views/Notifications';
import Questionnaire from 'modules/user/views/Questionnaire';
import Settings from 'modules/user/views/Settings';
import PaymentMethods from 'modules/user/views/Settings/components/PaymentMethods';
import Profile from 'modules/user/views/Settings/components/Profile';
import Security from 'modules/user/views/Settings/components/Security';
import Subscriptions from 'modules/user/views/Settings/components/Subscriptions';
import Verification from 'modules/user/views/Settings/components/Verification';
import VerificationID from 'modules/user/views/Settings/components/Verification/components/VerificationID';
import CloseAccount from 'modules/user/views/Settings/components/closeAccountProcess/CloseAccount';
import CloseAccountCheckList from 'modules/user/views/Settings/components/closeAccountProcess/CloseAccountCheckList';
import VaultDepositWithdraw from 'modules/vault/views/VaultDepositWithdraw';

import { SelectSidebar } from 'components/common';
import AnswerSidebar from 'components/common/AnswerSidebar';
import BankCardsDescription from 'components/common/BankCardsDescription';
import BankCardsFeaturesExplore from 'components/common/BankCardsFeaturesExplore';
import DescriptionListCarouselSidebar from 'components/common/DescriptionListCarouselSidebar';
import DynamicContent from 'components/common/DynamicContentSidebarItem';
import ReferralLinksMarketingAd from 'components/common/ReferralLinksMarketingAd';
import CreateBankCardSuccess from 'components/common/bankCards/CreateBankCardSuccess';

export const drawerComponentsByKey: { [key: string]: FC<any> } = {
  exchange: Exchange,
  exchangeSuccess: ExchangeSuccess,

  setupTFA: SetupTFA,
  checkTFACode: CheckTFACode,
  emergencyResetTFACode: EmergencyResetCode,

  transactionDetails: TransactionDetails,
  accountOperationsMore: AccountOperationsMore,
  accountStatement: AccountStatement,

  settings: Settings,
  profile: Profile,
  profileSecurity: Security,
  subscriptions: Subscriptions,
  notifications: Notifications,

  verification: Verification,
  verificationID: VerificationID,

  rentingSetup: RentingSetup,
  rentingSetupConfirm: RentingSetupConfirm,
  rentingSetupSuccess: RentingSetupSuccess,
  rentingFAQ: RentingFAQ,
  rentingNewCoinsAd: RentingNewCoinsAd,

  stakingSetup: StakingSetup,
  unstake: Unstake,
  stakingOperationConfirm: StakingOperationConfirm,
  stakingSuccessOperation: StakingSuccessOperation,

  paymentPickCurrency: PaymentPickCurrency,
  paymentPickOperation: PaymentPickOperation,
  paymentDepositCrypto: PaymentDepositCrypto,
  paymentDepositApplePay: PaymentDepositApplePay,
  paymentDepositSendBankCard: PaymentDepositSendBankCard,
  paymentDepositByDetails: PaymentDepositByDetails,
  paymentSendExternalWallet: PaymentSendExternalWallet,
  paymentSendNebeusUser: PaymentSendNebeusUser,
  paymentSendSEPA: PaymentSendSEPA,
  paymentSendWire: PaymentSendWire,
  paymentSendToDAOrCA: PaymentSendToDAOrCA,
  paymentAddSavedBankCard: PaymentAddSavedBankCard,
  paymentAddEditSavedCryptoWallet: PaymentAddSavedCryptoWallet,
  paymentMethods: PaymentMethods,
  paymentSelectCryptoNetwork: SelectCryptoNetwork,
  paymentSelectCryptoNetworkConfirmation: SelectCryptoNetworkConfirmation,
  accountDetails: AccountDetails,
  finishedOperation: FinishedOperation,

  confirmOTPCode: ConfirmOTPCode,

  select: SelectSidebar,
  answer: AnswerSidebar,
  dynamicContent: DynamicContent,
  descriptionListCarouselSidebar: DescriptionListCarouselSidebar,
  navBar: NavMenu,

  appOperations: AppOperations,

  selectCurrency: SelectCurrency,

  vaultDepositWithdraw: VaultDepositWithdraw,

  bankCards: BankCards,
  bankCardTerminate: TerminateBankCard,

  digitalAccountChooseSubscription: DigitalAccountChooseSubscription,
  digitalAccountGetSubscription: DigitalAccountGetSubscription,
  digitalAccountGetSubscriptionConfirm: DigitalAccountGetSubscriptionConfirm,
  digitalAccountGetSubscriptionSuccess: DigitalAccountGetSubscriptionSuccess,
  digitalAccountSubscriptionVerificationPending: DigitalAccountSubscriptionVerificationPending,
  digitalAccountSubscriptionRequested: DigitalAccountSubscriptionRequested,
  digitalAccountSubscriptionManagement: DigitalAccountSubscriptionManagement,
  digitalAccountCancelSubscription: DigitalAccountCancelSubscription,
  digitalAccountCancelSubscriptionSuccess: DigitalAccountCancelSubscriptionSuccess,
  downgradeSubscriptionToStandard: DowngradeSubscriptionToStandard,

  ibanAccountCreatingPrompt: IbanAccountCreatingPrompt,
  ibanAccountCreatingDeposit: IbanAccountCreatingDeposit,
  ibanAccountCreatingStatus: IbanAccountCreatingStatus,

  loanGetSuccess: LoanGetSuccess,
  loanRepayment: LoanRepayment,
  loanRepaid: LoanRepaid,
  loanAddCollateral: LoanAddCollateral,
  loanAddCollateralConfirm: LoanAddCollateralConfirm,
  loanLiquidationRiskReduced: LoanLiquidationRiskReduced,
  loanAddCollateralSuccess: LoanAddCollateralSuccess,
  loanMarginCall: LoanMarginCall,
  loanAutoPaymentFailed: LoanAutoPaymentFailed,
  loanTemplateDescription: TemplateDescription,

  smartTraderWelcome: SmartTraderWelcome,
  smartTraderPickAssets: SmartTraderPickAssets,
  smartTraderInsufficientFunds: SmartTraderInsufficientFunds,
  smartTraderExchange: SmartTraderExchange,
  smartTraderExchangeSuccess: SmartTraderExchangeSuccess,
  smartTraderSelectTradingPair: SmartTraderSelectTradingPair,
  smartTraderConfigureTrade: SmartTraderConfigureTrade,
  smartTraderSummaryConfirmation: SmartTraderSummaryConfirmation,
  smartTraderActivated: SmartTraderActivated,

  sessionExpiredWarning: SessionExpiredWarning,

  closeAccount: CloseAccount,
  closeAccountCheckList: CloseAccountCheckList,

  referralLinksMarketingAd: ReferralLinksMarketingAd,

  bankCardsDescription: BankCardsDescription,
  bankCardsFeaturesExplore: BankCardsFeaturesExplore,
  orderCryptoBankCardShippingDetails: OrderBankCardShippingDetails,
  orderCryptoBankCardConfirmation: OrderBankCardConfirmation,
  orderBankCardCalculatingShippingCosts: OrderBankCardCalculatingShippingCosts,
  orderBankCardDeliveryMethod: OrderBankCardDeliveryMethod,
  orderBankCardChooseType: OrderBankCardChooseType,
  descriptionCards: DescriptionCards,

  bankCardMoreOptions: BankCardMoreOptions,

  cryptoBankCardActivate: CryptoBankCardActivate,
  cryptoBankCardActivateSuccess: CryptoBankCardActivateSuccess,
  cryptoBankCardConnectAccount: CryptoBankCardConnectAccount,
  cryptoBankCardTransactions: CryptoBankCardTransactions,
  cryptoBankCardChangePinCode: CryptoBankCardChangePinCode,
  cryptoBankCardIntroducing: CryptoBankCardIntroducing,
  cryptoCardFeeAndConditions: CryptoCardFeeAndConditions,
  cryptoCardGoogleWalletOptionAd: GoogleWalletOptionAd,
  createBankCardSuccess: CreateBankCardSuccess,

  invoices: Invoices,
  createInvoice: CreateInvoice,
  createInvoiceConfirmation: CreateInvoiceConfirmation,
  invoicesSavedClients: SavedClients,
  editInvoiceStep: EditInvoiceStep,
  invoiceCreated: InvoiceCreated,
  invoice: InvoiceFull,

  menuList: MenuListSidebarView,
  confirmation: Confirmation,

  followedCurrencies: FollowedCurrencies,

  switchDefaultIban: SwitchDefaultIban,
  accountsTransactionsHistory: AccountsTransactionsHistory,

  questionnaire: Questionnaire,
  customerQuestionnaire: CustomerQuestionnaire,

  // phonebook
  phonebookCounterparties: Counterparties,
  phonebookAddEditCounterparty: AddEditCounterparty,
  phonebookCounterparty: Counterparty,
  phonebookAddEditPaymentDetails: AddEditPaymentDetails,
};

export const getComponentByKey = (key: SideBarStackItemKey) => drawerComponentsByKey[key];

export const verificationProtectedSidebars: SideBarStackItemKey[] = [
  'exchange',
  'paymentPickCurrency',
  'paymentDepositCrypto',
  'paymentPickOperation',
  'vaultDepositWithdraw',
  'loanAgreement',
  'paymentSendToDAOrCA',
  'stakingSetup',
  'unstake',
  'paymentSendNebeusUser',
  'orderCryptoBankCardShippingDetails',
  'orderCryptoBankCardConfirmation',
  'bankCardCreateSecurityQuestion',
  'ibanAccountCreatingDeposit',
];
