import { useCallback, useEffect } from 'react';

import useDrawer from 'modules/app/hooks/useDrawer';
import userDrawerTemplates from 'modules/user/constants/drawerTemplates';
import userServices from 'modules/user/services';

const useCustomerQuestionnaire = () => {
  const drawer = useDrawer();

  const checkIsCompleted = useCallback(async () => {
    try {
      const { completed } = await userServices.getCustomerQuestionnaireStatus();

      if (!completed) {
        drawer.open(userDrawerTemplates.customerQuestionnaire());
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Get customer questionnaire status failed: ', e);
    }
  }, [drawer]);

  useEffect(() => {
    checkIsCompleted();

    // eslint-disable-next-line
  }, []);
};

export default useCustomerQuestionnaire;
