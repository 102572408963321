import clsx from 'clsx';

import { FC, useMemo } from 'react';

import { Transaction, TransactionType } from 'modules/accounts/types';
import { TransactionStatusTag } from 'modules/accounts/views/components/TransactionStatusTag';

import { CurrencyIcon } from 'components/ui/CurrencyIcon';
import { Icon } from 'components/ui/Icon';

import { TranslationKey, useTranslation } from 'libs/i18n';

import { formatCurrencyWithLabel, formatCurrencyWithSymbol } from 'utils/currency';
import { formatHHMM } from 'utils/date';

import { CurrencyCode, voidFunc } from 'types';

import classes from './TransactionRow.module.scss';

interface TransactionRowProps {
  onClick: voidFunc;
  transaction: Transaction;
  className?: string;
  amountColored?: boolean;
}

interface TransactionIconProps {
  currencyCode: CurrencyCode;
  transactionType: TransactionType | null;
}

const iconByTransactionType: { [type in TransactionType]: string } = {
  bitgoIncomeTransaction: 'arrowLeftSafe',
  bitgoOutcomeTransaction: 'arrowRightSafe',
  cardReversalPayment: 'card2',
  correlation: 'arrowsUpDown',
  cryptoCard: 'card2',
  cryptoCardCashWithdrawal: 'atm',
  cryptoCardIncome: 'card2',
  cryptoCardOutcome: 'card2',
  deposit: 'arrowLeftSquaredSafe',
  depositApplePay: 'applePay',
  depositBTSEPA: 'arrowLeftSquaredSafe',
  depositBTWire: 'arrowLeftSquaredSafe',
  loan: 'percent',
  loanIncome: 'percent',
  loanOutcome: 'percent',
  modulrSubscription: 'nebeusCircle',
  modulrVirtualCard: 'moneyCard',
  moneyIncomeTransaction: 'iban',
  refLinkRewardsIn: 'present',
  rentingIncome: 'circlesUp',
  rentingOutcome: 'circlesUp',
  sendBTSEPA: 'arrowRightSquaredSafe',
  sendBTWire: 'arrowRightSquaredSafe',
  sendChaps: 'arrowRightSquaredSafe',
  sendFromCardToMoney: 'card2',
  sendFromCardToTrading: 'card2',
  sendFromMoneyToCard: 'card2',
  sendFromTradingToCard: 'card2',
  sendFromTradingToTrading: 'user',
  sendSEPACreditTransfer: 'arrowRightSquaredSafe',
  sendStandartPayment: 'arrowRightSquaredSafe',
  stakingIncome: 'stakingArrowUp',
  stakingOutcome: 'stakingArrowUp',
  vaultIncome: 'vault',
  vaultOutcome: 'vault',
  widgetTransaction: 'card2',
  wireTransaction: 'arrowRightSquaredSafe',
  exchangeTransaction: 'transferArrowsSquared',
  exchange: 'transferArrowsSquared',

  sendFasterPayment: 'arrowRightSquaredSafe',
  sendFasterPaymentFiatRepublic: 'arrowRightSquaredSafe',

  sendFromMoneyToTrading: 'arrowsUpDown',
  sendFromTradingToMoney: 'arrowsUpDown',
  sendFromTradingToFiatRepublic: 'arrowsUpDown',

  sendFromFiatRepublicToTrading: 'arrowsUpDown',

  sendSEPAInstantCreditTransfer: 'arrowRightSquaredSafe',
  sendSEPAInstantCreditTransferFiatRepublic: 'arrowRightSquaredSafe',
  fiatRepublicIncomeTransaction: 'iban',
  // TODO: add icons for these types
  cryptoAccount: '',
};

const subTypeLabelByTransactionType: {
  [type in TransactionType]:
    | TranslationKey
    | null
    | { [direction in 'in' | 'out']: TranslationKey };
} = {
  bitgoIncomeTransaction: 'TRANSACTION_CRYPTO_TRANSFER',
  bitgoOutcomeTransaction: 'TRANSACTION_CRYPTO_TRANSFER',
  cardReversalPayment: 'TRANSACTION_REVERSAL_PAYMENT',
  correlation: null,
  cryptoCard: 'TRANSACTION_UNIVERSAL_CARD',
  cryptoCardCashWithdrawal: 'TRANSACTION_UNIVERSAL_CARD_CASH_WITHDRAWAL',
  cryptoCardIncome: 'TRANSACTION_UNIVERSAL_CARD',
  cryptoCardOutcome: 'TRANSACTION_UNIVERSAL_CARD',
  deposit: 'TRANSACTION_INCOME',
  depositApplePay: 'TRANSACTION_APPLE_PAY',
  depositBTSEPA: 'TRANSACTION_SEPA',
  depositBTWire: 'TRANSACTION_WIRE',
  loan: 'TRANSACTION_LOAN',
  loanIncome: 'TRANSACTION_LOAN',
  loanOutcome: 'TRANSACTION_LOAN',
  modulrSubscription: 'TRANSACTION_MONEY_SUBSCRIPTION',
  modulrVirtualCard: 'TRANSACTION_MONEY_VIRTUAL_CARD',
  moneyIncomeTransaction: 'TRANSACTION_MONEY_INCOME',
  refLinkRewardsIn: 'TRANSACTION_REF_REWARD',
  rentingIncome: 'TRANSACTION_RENTING',
  rentingOutcome: 'TRANSACTION_RENTING',
  sendBTSEPA: 'TRANSACTION_SEPA',
  sendBTWire: 'TRANSACTION_WIRE',
  sendChaps: null,
  sendFromCardToMoney: null,
  sendFromCardToTrading: 'TRANSACTION_BANK_CARD_DEPOSIT',
  sendFromMoneyToCard: null,
  sendFromTradingToCard: 'TRANSACTION_BANK_CARD_WITHDRAWAL',
  sendFromTradingToMoney: 'TRANSACTION_BETWEEN_ACCOUNTS',
  sendFromTradingToTrading: {
    in: 'TRANSACTION_FROM_NEBEUS_USER',
    out: 'TRANSACTION_TO_NEBEUS_USER',
  },
  sendSEPACreditTransfer: 'TRANSACTION_SEPA',
  sendStandartPayment: null,
  stakingIncome: 'TRANSACTION_STAKING',
  stakingOutcome: 'TRANSACTION_STAKING',
  vaultIncome: 'TRANSACTION_VAULT',
  vaultOutcome: 'TRANSACTION_VAULT',
  widgetTransaction: 'TRANSACTION_WIDGET',
  wireTransaction: 'TRANSACTION_WIRE',
  exchangeTransaction: 'TRANSACTION_EXCHANGE',
  exchange: 'TRANSACTION_EXCHANGE',

  sendFasterPayment: null,
  sendFasterPaymentFiatRepublic: null,

  sendFromMoneyToTrading: 'TRANSACTION_BETWEEN_ACCOUNTS',
  sendFromFiatRepublicToTrading: 'TRANSACTION_BETWEEN_ACCOUNTS',

  sendSEPAInstantCreditTransfer: 'TRANSACTION_SEPA',
  sendSEPAInstantCreditTransferFiatRepublic: 'TRANSACTION_SEPA',
  fiatRepublicIncomeTransaction: 'TRANSACTION_MONEY_INCOME',
  sendFromTradingToFiatRepublic: 'TRANSACTION_BETWEEN_ACCOUNTS',

  // TODO: add icons for these types
  cryptoAccount: null,
};
const TransactionIcon: FC<TransactionIconProps> = ({ currencyCode, transactionType }) => {
  const iconName = transactionType && iconByTransactionType[transactionType];
  if (!iconName) {
    return <CurrencyIcon code={currencyCode} size={40} />;
  }
  return (
    <div className={classes.transactionIcon}>
      <Icon name={iconName} />
      <div className={classes.currencyIconMask}>
        <CurrencyIcon code={currencyCode} size={16} />
      </div>
    </div>
  );
};
export const TransactionRow: FC<TransactionRowProps> = ({
  transaction,
  onClick,
  className,
  amountColored = true,
}) => {
  const translate = useTranslation();

  const subType = useMemo(() => {
    if (transaction.operationId) {
      const subTypeLabel = subTypeLabelByTransactionType[transaction.operationId];
      if (subTypeLabel && typeof subTypeLabel === 'object') {
        return subTypeLabel[transaction.isIncome ? 'in' : 'out'];
      }
      return subTypeLabel;
    }
  }, [transaction]);

  const description = useMemo(() => {
    let result = transaction.description;

    if (transaction.additionalInfo) {
      if (
        'counterpartyEmail' in transaction.additionalInfo &&
        transaction.additionalInfo.counterpartyEmail
      ) {
        result += `. ${translate('EMAIL')}: ${transaction.additionalInfo.counterpartyEmail}`;
      }
      if ('reference' in transaction.additionalInfo && transaction.additionalInfo.reference) {
        result += `. ${translate('PAYMENT_REFERENCE')}: ${transaction.additionalInfo.reference}`;
      }
    }

    return result;
  }, [transaction, translate]);

  return (
    <div onClick={onClick} className={clsx(classes.root, className)}>
      <TransactionIcon
        currencyCode={transaction.currencyCode}
        transactionType={transaction.operationId}
      />
      <div className={classes.transactionColumn}>
        <div className={classes.transactionRow}>
          <span className={clsx(classes.boldLabel, classes.description)}>{description}</span>
          <span
            className={clsx(
              classes.amount,
              classes.boldLabel,
              amountColored && classes.colored,
              transaction.isIncome && classes.income,
              transaction.status !== 'completed' ? classes.notCompleted : undefined,
              transaction.status === 'failed' && classes.failed,
            )}
          >
            {transaction.isIncome ? '+ ' : '- '}
            {formatCurrencyWithLabel(Math.abs(transaction.amount), transaction.currencyCode)}
          </span>
        </div>
        <div className="row jcsb aic gap-2">
          <div className={classes.subTypeWithStatus}>
            <span className={clsx(classes.secondaryLabel, classes.subType)}>
              {formatHHMM(new Date(transaction.dateCreated))} {subType && `| ${translate(subType)}`}
            </span>
            {transaction.status !== 'completed' && (
              <TransactionStatusTag className={classes.statusTag} status={transaction.status} />
            )}
          </div>
          {transaction.amountInDefaultCurrency ? (
            <span className={clsx(classes.secondaryLabel, 'tar')}>
              {formatCurrencyWithSymbol(
                Math.abs(transaction.amountInDefaultCurrency),
                transaction.defaultCurrencyCode,
              )}
            </span>
          ) : null}
        </div>
      </div>
    </div>
  );
};
