import {
  CryptoBankCard,
  CryptoBankCardOrder,
  CryptoBankCardTransaction,
} from 'modules/cryptoBankCard/types';
import { transformList } from 'store/generators/transformers';

import {
  CryptoCardAllowedCurrencyResponse,
  CryptoCardDto,
  CryptoCardListResponse,
  CryptoCardPreOrderedInfoDto,
  CryptoCardPreOrderedInfoResponse,
  CryptoCardTransactionDto,
  CryptoCardTransactionListResponse,
} from 'libs/swagger/nebeusApiTypes';

import { CurrencyCode } from 'types';

export const transformAllowedCurrencies = (
  response: CryptoCardAllowedCurrencyResponse,
): CurrencyCode[] => response.allowedCurrencies as CurrencyCode[];

export const transformTransactions = (
  response: CryptoCardTransactionListResponse,
): { transactions: CryptoBankCardTransaction[] } => ({
  transactions: transformList<CryptoCardTransactionDto, CryptoBankCardTransaction>(
    response.transactions,
    [
      ['amountInLinkedWalletCurrency'],
      ['amountInMerchantCurrency'],
      ['dateCreated'],
      ['description'],
      ['id'],
      ['linkedWalletCurrencyCode', 'currencyCode'],
      ['merchantCurrencyCode'],
    ],
  ),
});

export const transformBankCards = (response: CryptoCardListResponse) =>
  transformList<CryptoCardDto, CryptoBankCard>(response.cards, [
    [
      'activated',
      'isActivated',
      {
        // @ts-ignore
        custom: (activated: boolean, cardDto: CryptoCardDto) => {
          if (!cardDto.virtual) {
            return activated;
          }
          return !!cardDto.linkedWalletCurrencyCode;
        },
      },
    ],
    ['blocked', 'isBlocked'],
    ['frozen', 'isFrozen'],
    ['id'],
    ['lastDigits'],
    ['linkedWalletCurrencyCode', 'currencyCode'],
    // @ts-ignore
    ['virtual', 'type', { custom: () => 'crypto' }],
    ['virtual', 'isVirtual', { custom: (value) => !!value }],
    ['pendingClosing', 'isClosingPending'],
    ['subscriptionNextPaymentTrial', 'nextPaymentDate'],
  ]);
export const transformBankCardsOrders = (response: CryptoCardPreOrderedInfoResponse) =>
  transformList<CryptoCardPreOrderedInfoDto, CryptoBankCardOrder>(response.preOrderedInfo, [
    ['cardId', 'id'],
    ['dateCreated'],
    ['paid'],
    ['paymentAmountInEur'],
    ['paymentCurrency'],
    ['preOrderedStatus'],
    ['read'],
    ['virtual', 'isVirtual'],
    ['pendingReason'],
    ['orderMinDeposit'],
  ]);
