import { combineReducers, configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { useDispatch as useNotTypedDispatch } from 'react-redux';

import accounts from 'modules/accounts/store';
import app from 'modules/app/store';
import auth from 'modules/auth/store';
import cryptoBankCard from 'modules/cryptoBankCard/store';
import cryptoRenting from 'modules/cryptoRenting/store';
import digitalAccount from 'modules/digitalAccount/store';
import exchange from 'modules/exchange/store';
import invoicing from 'modules/invoicing/store';
import loans from 'modules/loans/store';
import payment from 'modules/payment/store';
import phonebook from 'modules/phonebook/store';
import reactNative from 'modules/reactNative/store';
import smartTrader from 'modules/smartTrader/store';
import staking from 'modules/staking/store';
import user from 'modules/user/store';
import vault from 'modules/vault/store';

import appConfig from 'constants/appConfig';
import localStorageKeys from 'constants/localStorageKeys';

// cli-import
const loggerEnabled = false;
const middleware = appConfig.isDev && loggerEnabled ? [logger] : [];

const cryptoRentingPersistConfig = {
  key: 'cryptoRenting',
  storage: storage,
  blacklist: ['rentingItemsHistory', 'rentingItemsActive'],
};
const appPersistConfig = {
  key: 'app',
  storage: storage,
  blacklist: ['inited'],
};
const authPersistConfig = {
  key: 'auth',
  storage: storage,
  blacklist: ['authed'],
};

const invoicingPersistConfig = {
  key: 'invoicing',
  storage: storage,
  blacklist: ['invoices'],
};

const persistedCryptoRentingReducer = persistReducer(cryptoRentingPersistConfig, cryptoRenting);
const persistedAppReducer = persistReducer(appPersistConfig, app);
const persistedAuthReducer = persistReducer(authPersistConfig, auth);
const persistedInvoicingReducer = persistReducer(invoicingPersistConfig, invoicing);

const rootReducer = combineReducers({
  app: persistedAppReducer,
  auth: persistedAuthReducer,
  user,
  accounts,
  exchange,
  cryptoRenting: persistedCryptoRentingReducer,
  payment,
  staking,
  vault,
  digitalAccount,
  loans,
  smartTrader,
  reactNative,
  cryptoBankCard,
  invoicing: persistedInvoicingReducer,
  phonebook,
  // cli-reducer
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: ['cryptoRenting', 'app', 'auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => [
    ...getDefaultMiddleware({ serializableCheck: false }),
    ...middleware,
  ],
});

export const persistor = persistStore(store);

const PERSIST_VERSION = '27';

if (localStorage.getItem(localStorageKeys.persistVersion) !== PERSIST_VERSION) {
  // eslint-disable-next-line no-console
  console.log('PERSIST PURGE');
  persistor.purge();
  localStorage.setItem(localStorageKeys.persistVersion, PERSIST_VERSION);
}

export default store;

export type AppStore = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useDispatch: () => AppDispatch = useNotTypedDispatch;
